body {
  margin: 0;
  font-family: 'Roboto';

  .form {
    height: 100%;
    width: 100%;
  }

  /*******MUI CUSTOMIZATION********/
  .MuiFormLabel-colorError {
    color: #d32f2f;
  }
  .MuiListItemButton-root.Mui-selected {
    font-weight: bold;
  }
}
